import { initializeApp } from "firebase/app";

const conf = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "buildcv.app",
    // authDomain: "buildcv-app-cd890.firebaseapp.com",
    databaseURL: "https://buildcv-app-cd890-default-rtdb.firebaseio.com",
    projectId: "buildcv-app-cd890",
    storageBucket: "buildcv-app-cd890.appspot.com",
    messagingSenderId: "801719167890",
    appId: "1:801719167890:web:bb6f602e5247022a0eaf4b"
};

const app = initializeApp(conf);

export { app }